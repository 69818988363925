import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./portfolio.css";
import Header from "./header";
import Footer from "./footer";
import Blkmain from "./svg/blckchnmain.svg";
import Developmentsvg from "./svg/development.svg";
import Newtab from "./svg/newtab.svg";
import Loading from "./loader2";

const About = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleLogout = (e) => {};
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <Header />
          <Loading />
        </div>
      ) : (
        <>
          <div className="about_container">
            <section className="about_banner">
              <div className="about_center_name">
                <h1 className="about_name cssanimation sequence fadeInTop">
                  HI, I'M SRIDHAR
                </h1>
                <div className="tudiv">
                  <h1 className="about_description cssanimation sequence fadeInBottom">
                    FRONT END WEB DEVELOPER
                  </h1>
                </div>
                <a class="ca3-scroll-down-link ca3-scroll-down-arrow"></a>
              </div>

              <div className="about_center_namemq">
                <h1 className="about_namemq cssanimation sequence fadeInTop">
                  HI, I'M
                </h1>

                <h1 className="about_namemq cssanimation sequence fadeInTop">
                  SRIDHAR
                </h1>

                <h1 className="about_descriptionmq cssanimation sequence fadeInBottom">
                  FRONT END WEB
                </h1>
                <h1 className="about_descriptionmq cssanimation sequence fadeInBottom">
                  {" "}
                  DEVELOPER
                </h1>
                <a class="ca3-scroll-down-link ca3-scroll-down-arrow"></a>
              </div>
            </section>
            <section>
              <div className="about_container_memq">
                <h1 id="title_ani" className="intro_con_titlemq">
                  A LITTLE BIT ABOUT ME
                </h1>
                <div className="about_pic_containernq">
                  <img src="/images/sridhar-3.jpeg" className="pic_memq" />
                </div>
                <div className="about_para_containermq">
                  <div className="intro_para_containermq">
                    <h2 className="into_para_1mq">
                      Hello! I’m Sridhar, a passionate software developer
                      residing in Berlin, Germany. I specialize in front-end web
                      development with over 4 years of experience in creating
                      responsive and engaging user interfaces. My expertise
                      includes solving complex technical issues, conducting
                      in-depth research, and driving innovation in various
                      projects. Whether it's refining user interface designs for
                      seamless interactions or implementing responsive layouts
                      for diverse devices, I embrace challenges with enthusiasm.
                      Beyond code, I also enjoy playing Badminton, Table tennis
                      and Cooking. I've always eager to colloborate, learn and
                      contribute. <br></br>Feel free to reach out - I'd love to
                      connect! &nbsp;
                      <lnkd>
                        <a
                          href="https://www.linkedin.com/in/sridharsakthivel"
                          className="lnnkd"
                          target="_blank"
                        >
                          LinkedIn
                          <img
                            src={Newtab}
                            alt="view certificate"
                            className="newtab"
                          />
                        </a>
                      </lnkd>
                    </h2>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="Section_what_I_Do">
                <div className="wid_title">
                  <h1 id="title_ani1">WHAT I DO</h1>
                </div>

                <div className="wid_des">
                  <div className="wid_des_webdev">
                    <div className="bg_circle1">
                      <img
                        src={Developmentsvg}
                        alt="development"
                        className="codesvgpng"
                      />
                    </div>

                    <h1 id="title_ani2" className="wid_title_name">
                      WEB DEVELOPMENT
                    </h1>

                    <p className="wid_para_des">
                      As a web developer, I have a solid foundation in front-end
                      development technologies, with a particular expertise in
                      utilizing the React library to create responsive and
                      visually engaging user interfaces. My proficiency includes
                      designing and implementing reusable and maintainable
                      components, which significantly enhance application
                      efficiency and scalability. I possess a strong proficiency
                      in React, JavaScript, and TypeScript, coupled with
                      extensive experience in leveraging the Redux Context API
                      for efficient state management, ensuring optimized data
                      flow and enhancing overall application performance.
                      Furthermore, I place a strong emphasis on accessibility,
                      ensuring that my designs accommodate a diverse range of
                      user needs. In summary, my web development capabilities
                      are characterized by a comprehensive approach that
                      effectively bridges the gap between user experience and
                      technical execution.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Header />
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default About;
